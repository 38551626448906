<template>
  <div class="account-opening">
    <h2 class="text-cmain text-center">入力内容の確認</h2>
    <b-card no-body class="mb-5 py-3">
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          個人情報
        </b-card-title>
        <b-form-row>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.surname"
              label="姓"
              id="surname"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.firstname"
              label="名"
              id="firstname"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.surnameKana"
              label="姓よみがな"
              id="surname-kana"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
          <b-col cols="6">
            <base-input
              v-bind:value="storedData.personalInfo.firstnameKana"
              label="名よみがな"
              id="firstname-kana"
              v-bind:required="true"
              v-bind:disabled="true"
            >
            </base-input>
          </b-col>
        </b-form-row>
        <base-input
          v-bind:value="storedData.personalInfo.dateOfBirth"
          label="生年月日（西暦）"
          id="date-of-birth"
          type="date"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.genderText"
          label="性別"
          id="gender"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.address1"
          description="現在お住まいの住所の都道府県"
          label="住所1（現住所：都道府県）"
          id="address1"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.address2"
          description="現在お住まいの住所の市区町村"
          label="住所2（現住所：市町村区）"
          id="address2"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.address3"
          description="現在お住まいの住所の市区町村以降"
          label="住所3（現住所：市町村区以下）"
          id="address3"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.phoneNumber"
          label="連絡先電話番号"
          id="phone-number"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.email"
          label="メールアドレス"
          id="email"
          type="email"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.receptionTypeName"
          label="コイン受取方法"
          id="reception"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
      </b-card-body>
    </b-card>
    <b-card no-body class="mb-5 py-3">
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          本人性確認書類
        </b-card-title>
        <b-card
          v-if="storedData.identification.faceImage"
          header="ご自身の顔写真"
          class="mb-3"
        >
          <b-img
            v-bind:src="storedData.identification.faceImage"
            alt="Upload faceImage"
            center
            thumbnail
          />
        </b-card>
        <b-form-checkbox
          v-bind:checked="storedData.extraAddressRequired"
          class="box-large mb-3"
          name="extra-address"
          v-bind:disabled="true"
        >
          本人性確認書類記載の住所が現住所と異なる
        </b-form-checkbox>
        <base-input
          v-bind:value="storedData.personalInfo.extraAddress1"
          description="本人性確認書類（免許証など）記載の住所の都道府県"
          label="住所1（本人性確認書類記載の住所：都道府県）"
          id="extra-address1"
          v-bind:required="storedData.extraAddressRequired"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.extraAddress2"
          description="本人性確認書類（免許証など）記載の住所の市区町村"
          label="住所2（本人性確認書類記載の住所：市町村区）"
          id="extra-address2"
          v-bind:required="storedData.extraAddressRequired"
          v-bind:disabled="true"
        >
        </base-input>
        <base-input
          v-bind:value="storedData.personalInfo.extraAddress3"
          description="本人性確認書類（免許証など）記載の住所の市区町村以降"
          label="住所3（本人性確認書類記載の住所：市町村区以下）"
          id="extra-address3"
          v-bind:required="storedData.extraAddressRequired"
          v-bind:disabled="true"
        >
        </base-input>

        <base-input
          v-bind:value="storedData.identification.documentName1"
          label="本人性確認書類1"
          id="document1"
          v-bind:required="true"
          v-bind:disabled="true"
        >
        </base-input>
        <b-card
          v-if="storedData.identification.file11"
          header="表面"
          class="mb-3"
        >
          <b-img
            v-bind:src="storedData.identification.file11"
            alt="Upload image11"
            center
            thumbnail
          />
        </b-card>
        <b-card
          v-if="storedData.identification.file12"
          header="裏面"
          class="mb-3"
        >
          <b-img
            v-bind:src="storedData.identification.file12"
            alt="Upload image12"
            center
            thumbnail
          />
        </b-card>
        <div v-if="storedData.numberOfDocuments == 2">
          <base-input
            v-bind:value="storedData.identification.documentName2"
            label="本人性確認書類2"
            id="document2"
            v-bind:required="true"
            v-bind:disabled="true"
          >
          </base-input>
          <b-card
            v-if="storedData.identification.file21"
            header="表面"
            class="mb-3"
          >
            <b-img
              v-bind:src="storedData.identification.file21"
              alt="Upload image21"
              center
              thumbnail
            />
          </b-card>
          <b-card v-if="storedData.identification.file22" header="裏面">
            <b-img
              v-bind:src="storedData.identification.file22"
              alt="Upload image22"
              center
              thumbnail
            />
          </b-card>
        </div>
      </b-card-body>
    </b-card>
    <b-form-row>
      <b-col cols="6" offset-sm="2" sm="4">
        <b-button
          variant="secondary"
          class="w-100"
          v-bind:disabled="isProcessing"
          v-on:click="$router.back()"
        >
          入力内容を修正する
        </b-button>
      </b-col>
      <b-col cols="6" sm="4">
        <b-button
          variant="caccent"
          class="w-100"
          v-bind:disabled="isProcessing"
          v-on:click="sendApplication()"
        >
          口座開設を申請する
        </b-button>
      </b-col>
    </b-form-row>
  </div>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
export default {
  props: ['storedData'],
  components: {
    BaseInput,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  methods: {
    removeDataUrlHeader(file) {
      // 送信用に画像データ先頭のdata:image/jpeg;base64,を削除する
      if (file) {
        return file.replace(/^.*,/, '')
      } else {
        return null
      }
    },
    sendApplication() {
      console.log('** send application **')
      this.isProcessing = true
      this.$axios
        .post('/account_opening/application', {
          surname: this.storedData.personalInfo.surname,
          firstname: this.storedData.personalInfo.firstname,
          surnameKana: this.storedData.personalInfo.surnameKana,
          firstnameKana: this.storedData.personalInfo.firstnameKana,
          gender: this.storedData.personalInfo.gender,
          dateOfBirth: this.storedData.personalInfo.dateOfBirth,
          phoneNumber: this.storedData.personalInfo.phoneNumber,
          email: this.storedData.personalInfo.email,
          address1: this.storedData.personalInfo.address1,
          address2: this.storedData.personalInfo.address2,
          address3: this.storedData.personalInfo.address3,
          extraAddress1: this.storedData.personalInfo.extraAddress1,
          extraAddress2: this.storedData.personalInfo.extraAddress2,
          extraAddress3: this.storedData.personalInfo.extraAddress3,
          receptionType: this.storedData.personalInfo.receptionType,
          numberOfDocuments: this.storedData.numberOfDocuments,
          faceImage: this.removeDataUrlHeader(
            this.storedData.identification.faceImage
          ),
          document1: {
            code: this.storedData.identification.document1,
            front: this.removeDataUrlHeader(
              this.storedData.identification.file11
            ),
            back: this.removeDataUrlHeader(
              this.storedData.identification.file12
            ),
          },
          document2: {
            code: this.storedData.identification.document2,
            front: this.removeDataUrlHeader(
              this.storedData.identification.file21
            ),
            back: this.removeDataUrlHeader(
              this.storedData.identification.file22
            ),
          },
          linkageWfCode: this.$store.getters.linkageWfCode,
          linkageUserId: this.$store.getters.linkageUserId,
        })
        .then((response) => {
          console.log(response)
          // store連携情報クリア
          this.$store.dispatch('clearLinkageInfo')
          // 仮申請メッセージへ
          this.$router.push({
            name: 'application-message',
            params: { email: this.storedData.personalInfo.email },
          })
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('口座開設申請フォームの送信に失敗しました。')
          }
          this.isProcessing = false
        })
    },
  },
}
</script>

<style scoped>
.card-header {
  background-color: white;
  text-align: center;
}
</style>
